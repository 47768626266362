export const getStatusData = (status) => {
  if (status === '0') {
    return {
      styles: {
        color: '#1890FF',
        background: 'rgba(24, 144, 255, 0.08)',
        borderColor: 'rgba(24, 144, 255, 0.32)',
      },
      messageId: 'subscriptionPackagesUsed.table.body.pending',
    };
  }
  if (status === '1') {
    return {
      styles: {
        color: '#52C41A',
        background: 'rgba(82, 196, 26, 0.08)',
        borderColor: 'rgba(82, 196, 26, 0.32)',
      },
      messageId: 'subscriptionPackagesUsed.table.body.active',
    };
  }

  return {
    styles: {
      color: '#8C8C8C',
      background: 'rgba(191, 191, 191, 0.08)',
      borderColor: 'rgba(191, 191, 191, 0.32)',
    },
    messageId: 'subscriptionPackagesUsed.table.body.used',
  };
};

export default getStatusData;
