import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { request } from 'utils';
import { fetchInvoices } from 'actions';

export const usePortmoneForm = (url) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const [isFetching, toggleFetching] = useState(false);

  const setPayedStatus = useCallback(
    async (serviceId) => {
      await request(
        `${process.env.APP_USER_API_ROOT}/subscribe/payed/?service_id=${serviceId}&payed=1`,
        { withCredentials: true }
      );

      history.replace(pathname);

      dispatch(fetchInvoices());
    },
    [pathname]
  );

  const fetchPortmoneForm = useCallback(async () => {
    toggleFetching(true);

    try {
      const {
        data: { form, id: serviceId },
      } = await request(url, { withCredentials: true });

      if (form) {
        // eslint-disable-next-line no-eval
        eval(form);

        window.PG.success(() => {
          setPayedStatus(serviceId);

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'autoEvent',
            eventCategory: 'developers',
            eventAction: 'success',
            eventLabel: 'payment',
          });
        });
      } else {
        setPayedStatus(serviceId);
      }
    } finally {
      toggleFetching(false);
    }
  }, [url, setPayedStatus]);

  return [isFetching, fetchPortmoneForm];
};

export default usePortmoneForm;
