import { createSelector } from 'reselect';

export const getInvoices = (state) => state.invoices;

export const getInvoicesIsFetching = createSelector(
  getInvoices,
  (invoices) => invoices.isFetching
);

export const getInvoicesIsInitialized = createSelector(
  getInvoices,
  (invoices) => invoices.isInitialized
);

export const getInvoicesIds = createSelector(
  getInvoices,
  (invoices) => invoices.ids
);

export const getInvoicesEntities = createSelector(
  getInvoices,
  (invoices) => invoices.entities
);

export const getInvoicesItems = createSelector(
  getInvoicesIds,
  getInvoicesEntities,
  (ids, entities) => ids.map((id) => entities[id])
);

export const getPayedInvoice = createSelector(getInvoicesItems, (items) =>
  items.find(({ payed }) => payed)
);
