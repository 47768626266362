import { createSelector } from 'reselect';

import { getInvoicesEntities } from './invoices';
import { getSubscriptionsPreparedItems } from './subscriptions';

export const getPaymentsHistory = (state) => state.paymentsHistory;

export const getPaymentsHistoryIsFetching = createSelector(
  getPaymentsHistory,
  (paymentsHistory) => paymentsHistory.isFetching
);

export const getPaymentsHistoryIsInitialized = createSelector(
  getPaymentsHistory,
  (paymentsHistory) => paymentsHistory.isInitialized
);

export const getPaymentsHistoryIds = createSelector(
  getPaymentsHistory,
  (paymentsHistory) => paymentsHistory.ids
);

export const getPaymentsHistoryEntities = createSelector(
  getPaymentsHistory,
  (paymentsHistory) => paymentsHistory.entities
);

export const getPaymentsHistoryItems = createSelector(
  getPaymentsHistoryIds,
  getPaymentsHistoryEntities,
  (ids, entities) => ids.map((id) => entities[id])
);

export const getPaymentsHistoryWithSubscriptionInfo = createSelector(
  getPaymentsHistoryItems,
  getInvoicesEntities,
  getSubscriptionsPreparedItems,
  (paymentsHistoryItems, invoicesEntities, subscriptionsItems) => {
    paymentsHistoryItems.map((item) => {
      const invoice = invoicesEntities[item.serviceId];
      const subscription = subscriptionsItems.find(
        ({ id }) => invoice.planId === id
      );

      return {
        ...item,
        planName: subscription?.name || '',
      };
    });
  }
);
