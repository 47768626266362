import {
  FETCH_USER_SIGN_IN_REQUEST,
  FETCH_USER_SIGN_IN_SUCCESS,
  FETCH_USER_SIGN_IN_FAILURE,
  FETCH_USER_SIGN_OUT_SUCCESS,
  FETCH_USER_SIGN_OUT_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_TOKEN_SUCCESS,
  FETCH_USER_TOKEN_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {
  isAuthLoggedIn: false,
  authLoadError: null,

  /* ADD NEW PROFILE FIELDS HERE */
  profile: {
    id: null,
    type: 'personal',
    token: '',
    status: 0,
    requestUsed: 0,
    requestCount: 1,
    clientName: '',
    clientId: '',
    clientDescription: '',
    clientActivity: '',
    clientEmail: '',
    clientPhone: '',
    companyName: '',
    companyWebsite: '',
    subscription: 'free',
    subscribe: null,
    subscribeDueDate: '',
    subscribeEndAt: '',
  },
  isProfileLoaded: false,

  isInitialized: false,
};

const user = createReducer(initialState, {
  [FETCH_USER_PROFILE_SUCCESS]: (state, action) => {
    return {
      ...state,
      isAuthLoggedIn: action.payload.isAuthLoggedIn,
      isNewPartner: action.payload.isNewPartner,
      isVerificationPartner: action.payload.isVerificationPartner,

      profile: action.payload.profile,
      isProfileLoaded: true,

      isInitialized: true,
    };
  },

  [FETCH_USER_PROFILE_FAILURE]: (state) => {
    return {
      ...state,
      isAuthLoggedIn: false,
      isProfileLoaded: false,
      isInitialized: true,
    };
  },

  [FETCH_USER_SIGN_IN_REQUEST]: (state) => {
    return {
      ...state,
      authLoadError: null,
    };
  },

  [FETCH_USER_SIGN_IN_SUCCESS]: (state, action) => {
    return {
      ...state,
      authLoadError: null,
      isAuthLoggedIn: action.payload.isAuthLoggedIn,
      isNewPartner: action.payload.isNewPartner,
      isVerificationPartner: action.payload.isVerificationPartner,
    };
  },

  [FETCH_USER_SIGN_IN_FAILURE]: (state, action) => {
    return {
      ...state,
      isAuthLoggedIn: false,
      authLoadError: action.payload.error,
    };
  },

  [FETCH_USER_SIGN_OUT_SUCCESS]: (state) => {
    return {
      ...state,
      isAuthLoggedIn: false,
      isInitialized: true,
    };
  },

  [FETCH_USER_SIGN_OUT_FAILURE]: (state) => {
    return state;
  },

  [FETCH_USER_TOKEN_SUCCESS]: (state, action) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        token: action.payload.token,
      },
    };
  },

  [FETCH_USER_TOKEN_FAILURE]: (state) => {
    return state;
  },
});

export default user;
