export const LOCALES = {
  RU: 'ru',
  UK: 'uk',
};

export const DEFAULT_LOCALE = LOCALES.RU;

export const LANGUAGES = {
  EN: 'en',
  RU: 'ru',
  UA: 'ua',
};

export const getLocaleUrlCode = (locale) => {
  if (locale.toLowerCase() === LOCALES.UK) {
    return LANGUAGES.UA;
  }

  return locale.toLowerCase();
};

export const getLocaleUrlPath = (locale) => {
  if (locale.toLowerCase() === LOCALES.RU) {
    return '';
  }

  return `/${getLocaleUrlCode(locale)}`;
};

export const getUrlPathWithLocale = (locale, url) =>
  `${getLocaleUrlPath(locale)}${url}`;

export const getUrlPathWithoutLocale = (locale, url) => {
  if (locale.toLowerCase() === LOCALES.RU) {
    return url;
  }

  return url.replace(/^\/(ua|en)/, '');
};

export const getLocaleInDBFormat = (locale) => {
  if (!locale) {
    return LOCALES.RU;
  }

  if (locale === LANGUAGES.UA) {
    return LOCALES.UK;
  }

  return locale;
};
