import { normalize } from 'normalizr';
import { request } from 'utils';
import {
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_FAILURE,
} from 'actionTypes';

import { invoices as schema } from 'schemas';

export const fetchInvoices = () => async (dispatch) => {
  dispatch({
    type: FETCH_INVOICES_REQUEST,
    payload: {},
  });

  try {
    const response = await request(
      `${process.env.APP_USER_API_ROOT}/subscribe/list/`,
      {
        withCredentials: true,
      }
    );

    const { entities: { invoices: entities = {} } = {}, result: ids = [] } =
      response.data && response.data.length
        ? normalize(response.data, schema)
        : {};

    return dispatch({
      type: FETCH_INVOICES_SUCCESS,
      payload: {
        ids,
        entities,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_INVOICES_FAILURE,
      payload: {},
    });
  }
};

export default fetchInvoices;
