import { request } from 'utils';
import {
  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE,
} from 'actionTypes';

export const fetchStats = ({ startDate, endDate }) => async (dispatch) => {
  dispatch({
    type: FETCH_STATS_REQUEST,
    payload: {},
  });

  try {
    const response = await request(
      `${process.env.APP_USER_API_ROOT}/stat/?date_start=${startDate}&date_stop=${endDate}`,
      { withCredentials: true }
    );

    return dispatch({
      type: FETCH_STATS_SUCCESS,
      payload:
        response && response.data
          ? {
              typeList: response.data.typeList,
              stats: response.data.list.map(
                ({ timestamp: date, type, requestCount }) => ({
                  type,
                  date,
                  requestCount: parseInt(requestCount, 10),
                })
              ),
            }
          : { typeList: [], stats: [] },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_STATS_FAILURE,
      payload: {},
    });
  }
};

export default fetchStats;
