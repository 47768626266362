import { createSelector } from 'reselect';

export const getUser = (state) => state.user;

export const getUserAuthIsLoggedIn = createSelector(
  getUser,
  (user) => user.isAuthLoggedIn
);

export const getUserAuthLoadError = createSelector(
  getUser,
  (user) => user.authLoadError
);

export const getUserProfile = createSelector(getUser, (user) => user.profile);

export const getUserProfileIsLoaded = createSelector(
  getUser,
  (user) => user.isProfileLoaded
);

export const getUserIsInitialized = createSelector(
  getUser,
  (user) => user.isInitialized
);

export const getUserIsNewPartner = createSelector(
  getUser,
  (user) => user.isNewPartner
);

export const getCardMask = createSelector(getUserProfile, (user) => {
  if (user.cardMask) {
    const firstNumber = user.cardMask[0];

    if (firstNumber === '4') return 'visa';
    if (firstNumber === '5') return 'mastercard';

    return null;
  }

  return 'noActiveCards';
});

export const getCardLastNumbers = createSelector(
  getUserProfile,
  (user) => user.cardMask.slice(-4) || ''
);

export const getVerificationPartner = createSelector(
  getUser,
  (user) => user.isVerificationPartner
);
