import { createSelector } from 'reselect';

export const getStats = (state) => state.stats;

export const getStatsIsFetching = createSelector(
  getStats,
  (stats) => stats.isProfileLoaded
);

export const getStatsIsInitialized = createSelector(
  getStats,
  (stats) => stats.isInitialized
);

export const getStatsTypeList = createSelector(
  getStats,
  (stats) => stats.typeList
);

export const getStatsData = createSelector(getStats, (stats) => stats.stats);

export const getGroupedStats = createSelector(getStatsData, (stats) =>
  stats.reduce(
    (result, { type, requestCount }) => ({
      ...result,
      [type]: {
        ...(result[type] || {}),
        type,
        requestCount: (result[type]?.requestCount || 0) + requestCount,
      },
    }),
    {}
  )
);

export const getGroupedStatsItems = createSelector(
  getStatsTypeList,
  getGroupedStats,
  (typeList, stats) =>
    typeList
      .map((type) => stats[type])
      .filter(Boolean)
      .sort(
        ({ requestCount: valueA }, { requestCount: valueB }) => valueB - valueA
      )
);
