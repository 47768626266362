import { normalize } from 'normalizr';
import { request } from 'utils';
import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
} from 'actionTypes';

import { subscriptions as schema } from 'schemas';

export const fetchSubscriptions = () => async (dispatch) => {
  dispatch({
    type: FETCH_SUBSCRIPTIONS_REQUEST,
    payload: {},
  });

  try {
    const response = await request(`${process.env.APP_USER_API_ROOT}/plan/`, {
      withCredentials: true,
    });

    const {
      entities: { subscriptions: entities = {} } = {},
      result: ids = [],
    } =
      response.data && response.data.length
        ? normalize(response.data, schema)
        : {};

    return dispatch({
      type: FETCH_SUBSCRIPTIONS_SUCCESS,
      payload: {
        ids,
        entities,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_SUBSCRIPTIONS_FAILURE,
      payload: {},
    });
  }
};

export default fetchSubscriptions;
