import {
  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {
  typeList: [],
  stats: [],
  isFetching: false,
  isRequestFailed: false,
  isInitialized: false,
};

const stats = createReducer(initialState, {
  [FETCH_STATS_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    isRequestFailed: false,
  }),

  [FETCH_STATS_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isInitialized: true,
  }),

  [FETCH_STATS_FAILURE]: (state) => ({
    ...state,
    isFetching: false,
    isRequestFailed: true,
    isInitialized: true,
  }),
});

export default stats;
