import React from 'react';
import { render } from 'react-dom';

import Root from 'components/Root';

import configureStore from 'store';

const store = configureStore();

const rootElement = document.getElementById('root');

const renderRoot = (Component) => {
  render(<Component store={store} routerBasename="" />, rootElement);
};

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('components/Root', () => {
    const Component = require('components/Root').default; //eslint-disable-line

    renderRoot(Component);
  });
}

renderRoot(Root);
