import React, { memo, Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import antdUk from 'antd/es/locale/uk_UA';
import antdRu from 'antd/es/locale/ru_RU';
import antdEn from 'antd/es/locale/en_GB';

import { GlobalStyle, getLocaleInDBFormat } from 'utils';

import translationsRU from 'translations/ru.json';
import translationsUK from 'translations/uk.json';
import translationsEN from 'translations/en.json';

import App from 'components/App';

/* eslint-disable */
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/ru');
  require('@formatjs/intl-pluralrules/dist/locale-data/uk');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/ru');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/uk');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
}
/* eslint-enable */

const translations = {
  ru: translationsRU,
  uk: translationsUK,
  en: translationsEN,
};

const antdLocales = {
  ru: antdRu,
  uk: antdUk,
  en: antdEn,
};

function IntlRoot() {
  const params = useParams();

  const locale = getLocaleInDBFormat(params.locale);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={locale}
      messages={translations[locale]}
      textComponent={Fragment}
    >
      {/* antd config */}
      <ConfigProvider locale={antdLocales[locale]}>
        <App />
        <GlobalStyle />
      </ConfigProvider>
    </IntlProvider>
  );
}

export default memo(IntlRoot);
