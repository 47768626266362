// eslint-disable-next-line import/prefer-default-export
export const activities = [
  'production',
  'trading',
  'service',
  'agribusiness',
  'finance',
  'online-store',
  'app',
  'chat-bot',
  'crm-erp',
  'non-commercial',
];

export const includes = [
  'mb',
  'additional',
  'summary',
  'exchrates',
  'nbu',
  'auction',
  'card',
  'contracts',
];
