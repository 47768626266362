import Cookies from 'js-cookie';

export const setCookie = (name, value, options) => {
  return Cookies.set(name, value, options);
};

export const getCookie = (name, options) => {
  return Cookies.get(name, options);
};

export const deleteCookie = (name, options) => {
  Cookies.remove(name, options);
};
