import React, { memo, useCallback, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { fetchUserProfile } from 'actions';
import {
  getUserAuthIsLoggedIn,
  getUserIsInitialized,
  getUserProfileIsLoaded,
  getUserIsNewPartner,
  getVerificationPartner,
} from 'selectors';
import { usePathFormatter } from 'hooks';

import routes from 'routes';

const DISABLE_AUTH_CHECK =
  process.env.NODE_ENV === 'development' &&
  process.env.APP_DISABLE_AUTH_CHECK === 'true';

function App() {
  const pathFormatter = usePathFormatter();
  const dispatch = useDispatch();

  const isUserAuthLoggedIn = useSelector(getUserAuthIsLoggedIn);
  const isUserInitialized = useSelector(getUserIsInitialized);
  const isUserProfileIsLoaded = useSelector(getUserProfileIsLoaded);
  const isNewPartner = useSelector(getUserIsNewPartner);
  const isVerificationPartner = useSelector(getVerificationPartner);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  const renderRoute = useCallback(
    ({ component: RouteComponent, id, type, props, ...routeProps }) => {
      return (
        <Route {...routeProps} key={id}>
          {DISABLE_AUTH_CHECK ? (
            <RouteComponent {...props} />
          ) : (
            <>
              {/* render common (docs) route */}
              {type === 'common' && <RouteComponent {...props} />}

              {/* redirect from private (home, payment) or auth route to 2nd registration if user is new partner */}
              {type === 'private' && isUserAuthLoggedIn && isNewPartner && (
                <Redirect
                  to={pathFormatter('/registration_short/', {
                    withSearch: true,
                  })}
                />
              )}

              {/* render private (home, payment) route if user logged in */}
              {type === 'private' && isUserAuthLoggedIn && !isNewPartner && (
                <RouteComponent {...props} />
              )}

              {/* redirect from private page (home, payment) to auth or registration page if user not logged in */}
              {type === 'private' && !isUserAuthLoggedIn && (
                <Redirect
                  to={pathFormatter(
                    isUserProfileIsLoaded ? '/login/' : '/registration/',
                    { withSearch: true }
                  )}
                />
              )}

              {type === 'private' &&
                !isUserAuthLoggedIn &&
                isVerificationPartner && (
                  <Redirect
                    to={pathFormatter('/phone-verification/', {
                      withSearch: true,
                    })}
                  />
                )}

              {type === 'auth' &&
                ((!isUserAuthLoggedIn && isVerificationPartner) ||
                  !isUserAuthLoggedIn ||
                  isNewPartner) && <RouteComponent {...props} />}

              {type === 'auth' &&
                !isUserAuthLoggedIn &&
                !isVerificationPartner && (
                  <Redirect
                    to={pathFormatter(
                      isUserProfileIsLoaded ? '/login/' : '/registration/',
                      { withSearch: true }
                    )}
                  />
                )}

              {/* redirect from auth page to home page if user was logged in */}
              {type === 'auth' && isUserAuthLoggedIn && !isNewPartner && (
                <Redirect
                  to={pathFormatter('/', {
                    withSearch: true,
                  })}
                />
              )}
            </>
          )}
        </Route>
      );
    },
    [isUserAuthLoggedIn, isNewPartner, isVerificationPartner]
  );

  if (!isUserInitialized) {
    /* render nothing before fetch auth status */
    return null;
  }

  return <Switch>{routes.map(renderRoute)}</Switch>;
}

export default memo(App);
