import { createSelector } from 'reselect';

import { getUserProfile } from './user';
import { getInvoicesItems, getPayedInvoice } from './invoices';

export const getSubscriptions = (state) => state.subscriptions;

export const getSubscriptionsIsFetching = createSelector(
  getSubscriptions,
  (subscriptions) => subscriptions.isFetching
);

export const getSubscriptionsIsInitialized = createSelector(
  getSubscriptions,
  (subscriptions) => subscriptions.isInitialized
);

export const getSubscriptionsIds = createSelector(
  getSubscriptions,
  (subscriptions) => subscriptions.ids
);

export const getSubscriptionsEntities = createSelector(
  getSubscriptions,
  (subscriptions) => subscriptions.entities
);

export const getSubscriptionsItems = createSelector(
  getSubscriptionsIds,
  getSubscriptionsEntities,
  (ids, entities) => ids.map((id) => entities[id])
);

export const getSubscriptionsPreparedItems = createSelector(
  getSubscriptionsItems,
  (state, props) => props.locale,
  (items, locale) => {
    return items
      .filter(({ show }) => show)
      .map((item) => ({
        name: item[`name-${locale}`],
        ...item,
      }))
      .sort(({ order: orderA }, { order: orderB }) => orderA - orderB);
  }
);

export const getSubscriptionsAllItems = createSelector(
  getSubscriptionsItems,
  (state, props) => props.locale,
  (items, locale) => {
    return items
      .map((item) => ({
        name: item[`name-${locale}`],
        ...item,
      }))
      .sort(({ order: orderA }, { order: orderB }) => orderA - orderB);
  }
);

export const getMonthlySubscriptionsItems = createSelector(
  getSubscriptionsPreparedItems,
  (subscriptions) =>
    subscriptions.filter(
      ({ period, slug }) => period === 'month' || slug === 'free'
    )
);

export const getYearlySubscriptionsItems = createSelector(
  getSubscriptionsPreparedItems,
  (subscriptions) =>
    subscriptions.filter(
      ({ period, slug }) => period === 'year' || slug === 'free'
    )
);

export const getYearlyJurSubscriptionsItems = createSelector(
  getSubscriptionsPreparedItems,
  (subscriptions) => subscriptions.filter(({ period }) => period === 'year')
);

export const getActiveSubscription = createSelector(
  getSubscriptionsAllItems,
  getUserProfile,
  (items, { subscription }) => {
    return items.find(({ slug }) => slug === subscription);
  }
);

export const getNewSubscription = createSelector(
  getSubscriptionsPreparedItems,
  getPayedInvoice,
  (items, payedInvoice) =>
    payedInvoice
      ? items.find(
          ({ id }) => payedInvoice.status === '0' && id === payedInvoice.planId
        )
      : null
);

export const getActiveSubscriptionItems = createSelector(
  getInvoicesItems,
  getActiveSubscription,
  (items, subscription) =>
    items.filter((item) => {
      return item.parent && item.planId === subscription?.id;
    })
);

export const getExpiredSubscriptionItems = createSelector(
  getActiveSubscriptionItems,
  (active) => active.filter((item) => item.status !== '0')
);
