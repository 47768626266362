import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useSelectorProps = () => {
  const { locale } = useIntl();

  const selectorProps = useMemo(() => ({ locale }), [locale]);

  return selectorProps;
};

export default useSelectorProps;
