import loadable from '@loadable/component';

const HomePage = loadable(() => import('components/HomePage'));
const AuthPage = loadable(() => import('components/AuthPage'));
const DocsPage = loadable(() => import('components/DocsPage'));

const routes = [
  {
    id: 'docsPage',
    path: '/:locale(ua|en)?/:page(docs)/',
    strict: true,
    exact: true,
    component: DocsPage,
    props: {},
    type: 'common',
  },
  {
    id: 'authPage',
    path:
      '/:locale(ua|en)?/:authType(login|registration|registration_short|forgot-password|phone-verification)/',
    strict: true,
    exact: true,
    component: AuthPage,
    props: {},
    type: 'auth',
  },
  {
    id: 'homePage',
    path: '/:locale(ua|en)?/',
    strict: true,
    exact: false,
    component: HomePage,
    props: {},
    type: 'private',
  },
];

export default routes;
