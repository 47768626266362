import { combineReducers } from 'redux';

import user from './user';
import stats from './stats';
import subscriptions from './subscriptions';
import invoices from './invoices';
import paymentsHistory from './paymentsHistory';

const rootReducer = combineReducers({
  user,
  stats,
  subscriptions,
  invoices,
  paymentsHistory,
});

export default rootReducer;
