import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { getLocaleUrlPath } from 'utils';

export const usePathFormatter = () => {
  const { locale } = useIntl();
  const { search } = useLocation();

  const localeUrl = getLocaleUrlPath(locale);

  const formatter = useCallback(
    (url, { withSearch = false } = {}) =>
      `${localeUrl}${url}${withSearch ? search : ''}`,
    [locale, search]
  );

  return formatter;
};

export default usePathFormatter;
