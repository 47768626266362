import { schema } from 'normalizr';

const dataOrder = {
  mb: 0,
  additional: 1,
  summary: 2,
  exchrates: 3,
  auction: 4,
  nbu: 5,
  card: 6,
  contracts: 7,
};

export const subscription = new schema.Entity(
  'subscriptions',
  {},
  {
    processStrategy: ({
      id,
      name,
      nameUa,
      nameEn,
      slug,
      price,
      requestCount,
      dataRelevance,
      oldPrice,
      order,
      period,
      show,
      label,
      data,
    }) => ({
      id: parseInt(id, 10),
      'name-ru': name,
      'name-uk': nameUa,
      'name-en': nameEn,
      slug,
      dataRelevance: parseInt(dataRelevance, 10),
      price: price && parseInt(price, 10),
      oldPrice: oldPrice && parseInt(oldPrice, 10),
      requestCount: requestCount && parseInt(requestCount, 10),
      order: slug === 'free' ? 10000 : parseInt(order, 10),
      period,
      show: !!parseInt(show, 10),
      label,
      data: data.slice().sort((a, b) => dataOrder[a] - dataOrder[b]),
    }),
  }
);

export const subscriptions = [subscription];
