export const loginRegExp = /^([A-Za-z0-9]){3,15}$/;

export const passwordRegExp = /^([ A-Za-z0-9 ])+.{5,40}$/;

export const emailRegExp = /^[a-zA-Z0-9.\-_]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,200}))$/;

export const emailRegExp2 = /^[a-zA-Z0-9.\-_]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,251}))$/;

export const telRegExp = /\+?38\s?\(?0[0-9]{2}\)?\s?[0-9]{3}-?[0-9]{2}-?[0-9]{2}/;

export const spaceRegExp = /^([A-Za-zА-яїєіґё'`—-])+$/;

export const numberRegExp = /^([0-9]){5}$/;
