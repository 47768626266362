import { useState, useCallback } from 'react';

export const useFormState = (defaultState) => {
  const [values, setValues] = useState(defaultState);

  const [errors, setErrors] = useState({});

  const handleChange = useCallback(({ target: { name, value } }) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: null,
    }));
  }, []);

  return { values, setValues, errors, setErrors, handleChange };
};

export default useFormState;
