import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f0f2f5;
  }
  
  #root {
    background-color: #f0f2f5;
    min-height: 100%;
  }

  .ant-space-vertical {
    width: 100%;
  }

  .ant-form-vertical .ant-form-item-label.ant-col-offset-3 {
    margin-left: 12.5%;
  }
`;

export default GlobalStyle;
