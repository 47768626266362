import Cookies from 'js-cookie';

const activity = {
  production: 'Производство',
  trading: 'Торговля',
  service: 'Услуги',
  agribusiness: 'Агробизнес',
  finance: 'Финансовая деятельность',
  'online-store': 'Интернет-магазин',
  app: 'Мобильное приложение',
  'chat-bot': 'Чат-бот',
  'crm-erp': 'CRM/ERP система',
  'non-commercial': 'Некоммерческая деятельность',
};
export const pushClevertapProfileJur = (params = {}) => {
  const clientID = Cookies.get(`_ga`);

  if (window.clevertap) {
    window.clevertap.profile.push({
      Site: {
        'Client ID': clientID,
        Platform: 'web',
        ...params,
      },
    });
  }
};
export const pushClevertapProfile = (params = {}) => {
  const clientID = Cookies.get(`_ga`);

  if (window.clevertap) {
    window.clevertap.profile.push({
      Site: {
        'Client ID': clientID,
        Platform: 'web',
        ...params,
        'Developers Company Activity':
          activity[params['Developers Company Activity']],
        'Developers Profile Type':
          params['Developers Profile Type'] === 'company'
            ? 'Компания'
            : 'Персональный',
      },
    });
  }
};

export const pushClevertapPageView = (params = {}) => {
  if (window.clevertap) {
    window.clevertap.event.push('Pageview', {
      'Product type': 'Currency Api',
      Project: 'minfin.com.ua',
      ...params,
    });
  }
};

export const pushCleverTap = (params = {}, type) => {
  if (window.clevertap) {
    window.clevertap.event.push(type, {
      'Product type': 'Currency Api',
      ...params,
    });
  }
};

export default pushClevertapProfile;
