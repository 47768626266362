export const FETCH_USER_SIGN_IN_REQUEST = 'FETCH_USER_SIGN_IN_REQUEST';
export const FETCH_USER_SIGN_IN_SUCCESS = 'FETCH_USER_SIGN_IN_SUCCESS';
export const FETCH_USER_SIGN_IN_FAILURE = 'FETCH_USER_SIGN_IN_FAILURE';

export const FETCH_USER_SIGN_OUT_SUCCESS = 'FETCH_USER_SIGN_OUT_SUCCESS';
export const FETCH_USER_SIGN_OUT_FAILURE = 'FETCH_USER_SIGN_OUT_FAILURE';

export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';

export const FETCH_USER_TOKEN_SUCCESS = 'FETCH_USER_TOKEN_SUCCESS';
export const FETCH_USER_TOKEN_FAILURE = 'FETCH_USER_TOKEN_FAILURE';
