import {
  FETCH_PAYMENTS_HISTORY_REQUEST,
  FETCH_PAYMENTS_HISTORY_SUCCESS,
  FETCH_PAYMENTS_HISTORY_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {
  ids: [],
  entities: {},
  isFetching: false,
  isRequestFailed: false,
  isInitialized: false,
};

const subscriptions = createReducer(initialState, {
  [FETCH_PAYMENTS_HISTORY_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    isRequestFailed: false,
  }),

  [FETCH_PAYMENTS_HISTORY_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isInitialized: true,
  }),

  [FETCH_PAYMENTS_HISTORY_FAILURE]: (state) => ({
    ...state,
    isFetching: false,
    isRequestFailed: true,
    isInitialized: true,
  }),
});

export default subscriptions;
