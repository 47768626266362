import moment from 'moment';

import {
  request,
  getCookie,
  setCookie,
  deleteCookie,
  normalizePhone,
} from 'utils';

import {
  FETCH_USER_SIGN_IN_REQUEST,
  FETCH_USER_SIGN_IN_SUCCESS,
  FETCH_USER_SIGN_IN_FAILURE,
  FETCH_USER_SIGN_OUT_SUCCESS,
  FETCH_USER_SIGN_OUT_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_TOKEN_SUCCESS,
  FETCH_USER_TOKEN_FAILURE,
} from 'actionTypes';

export const fetchUserProfile = (type) => async (dispatch) => {
  try {
    const token = getCookie('site_session');

    const { data } = await request(process.env.APP_USER_API_ROOT, {
      withCredentials: true,
    });

    /* ADD NEW PROFILE FIELDS HERE */
    const profile = {
      id: data.id,
      type: data.type,
      token: data.token || '',
      status: parseInt(data.status, 10),
      requestUsed: parseInt(data.requestUsed || 0, 10),
      requestCount: parseInt(data.requestCount || 0, 10),
      clientName: data.name || '',
      clientId: data.id || '',
      clientDescription: data.description || '',
      clientActivity: data.activity || '',
      clientEmail: data.email || '',
      clientPhone: data.phone ? normalizePhone(data.phone) : '',
      companyName: data.clientName || '',
      companyWebsite: data.website || '',
      subscription: data.subscription || 'free',
      subscribe: data.subscribe ? parseInt(data.subscribe, 10) : null,
      subscribeDueDate: data.subscribeNextCharge || '',
      subscribeEndAt: moment(data.subscribeEndAt).valueOf()
        ? data.subscribeEndAt
        : '',
      cardMask: data.cardMask,
    };

    return dispatch({
      type: FETCH_USER_PROFILE_SUCCESS,
      payload: {
        profile,
        isNewPartner: profile.status === 2,
        isAuthLoggedIn: !!token && profile.status === 1,
        isVerificationPartner:
          type === 'registration' ? true : profile.status === 0,
      },
    });
  } catch (error) {
    const errorMessage =
      error && error.message && error.message === 'invalidToken'
        ? error.message
        : 'serverError';

    deleteCookie('site_session');

    return dispatch({
      type: FETCH_USER_PROFILE_FAILURE,
      payload: { error: errorMessage },
    });
  }
};

export const fetchUserSignIn = (
  { login, password } = {},
  redirect = () => {},
  type
) => async (dispatch) => {
  try {
    if (!(login && password)) {
      throw new Error('invalidCredentials');
    }

    dispatch({
      type: FETCH_USER_SIGN_IN_REQUEST,
      payload: {},
    });

    const {
      data: { token },
    } = await request(`${process.env.APP_USER_API_ROOT}/auth`, {
      method: 'post',
      data: `Login=${login}&Password=${password}`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (!token) {
      throw new Error('invalidCredentials');
    }

    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);

    setCookie('site_session', token, { expires: date });

    const { data } = await request(process.env.APP_USER_API_ROOT, {
      withCredentials: true,
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'autoEvent',
      eventCategory: 'user',
      eventAction: 'authorized',
      eventLabel: data.uid,
    });

    setCookie('userid', data.uid);

    const status = parseInt(data.status, 10);

    if (status === 2 || status === 0) {
      redirect(status);
    }

    dispatch({
      type: FETCH_USER_SIGN_IN_SUCCESS,
      payload: {
        isNewPartner: status === 2,
        isAuthLoggedIn: status === 1,
        isVerificationPartner: type === 'registration',
      },
    });

    return dispatch(fetchUserProfile(type));
  } catch (error) {
    const errorMessage =
      error && error.message && error.message === 'invalidCredentials'
        ? error.message
        : 'serverError';

    return dispatch({
      type: FETCH_USER_SIGN_IN_FAILURE,
      payload: {
        error: errorMessage || 'serverError',
      },
    });
  }
};

export const fetchUserSignOut = () => async (dispatch) => {
  try {
    deleteCookie('site_session');

    return dispatch({
      type: FETCH_USER_SIGN_OUT_SUCCESS,
      payload: {},
    });
  } catch (error) {
    return dispatch({
      type: FETCH_USER_SIGN_OUT_FAILURE,
      payload: {
        error,
      },
    });
  }
};

export const fetchUserToken = () => async (dispatch) => {
  try {
    const response = await request(
      `${process.env.APP_USER_API_ROOT}/generate/`,
      {
        withCredentials: true,
      }
    );

    return dispatch({
      type: FETCH_USER_TOKEN_SUCCESS,
      payload: {
        token: response.data.token,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_USER_TOKEN_FAILURE,
      payload: { error: 'serverError' },
    });
  }
};
