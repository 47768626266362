import {
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {
  ids: [],
  entities: {},
  isFetching: false,
  isRequestFailed: false,
  isInitialized: false,
};

const invoices = createReducer(initialState, {
  [FETCH_INVOICES_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    isRequestFailed: false,
  }),

  [FETCH_INVOICES_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isInitialized: true,
  }),

  [FETCH_INVOICES_FAILURE]: (state) => ({
    ...state,
    isFetching: false,
    isRequestFailed: true,
    isInitialized: true,
  }),
});

export default invoices;
