import { schema } from 'normalizr';

export const invoice = new schema.Entity(
  'invoices',
  {},
  {
    processStrategy: ({
      id,
      planId,
      customerId,
      startAt,
      endAt,
      status,
      payed,
      nextCharge,
      timestamp,
      parent,
    }) => ({
      id: parseInt(id, 10),
      planId: parseInt(planId, 10),
      customerId: parseInt(customerId, 10),
      status,
      payed: !!parseInt(payed, 10),
      startAt,
      endAt,
      nextCharge,
      timestamp,
      parent,
    }),
  }
);

export const invoices = [invoice];
