import { schema } from 'normalizr';

export const paymentHistory = new schema.Entity(
  'paymentsHistory',
  {},
  {
    idAttribute: (item) => parseInt(item.serviceId, 10),
    processStrategy: ({ serviceId, date, cardMask, price }) => ({
      serviceId: parseInt(serviceId, 10),
      date,
      cardMask,
      price: parseInt(price, 10),
    }),
  }
);

export const paymentsHistory = [paymentHistory];
