import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

export const useSearchParams = () => {
  const { search } = useLocation();

  const parseSearch = parse(search);

  return parseSearch;
};

export default useSearchParams;
