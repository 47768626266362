import { normalize } from 'normalizr';
import moment from 'moment';

import { request } from 'utils';
import {
  FETCH_PAYMENTS_HISTORY_REQUEST,
  FETCH_PAYMENTS_HISTORY_SUCCESS,
  FETCH_PAYMENTS_HISTORY_FAILURE,
} from 'actionTypes';

import { paymentsHistory as schema } from 'schemas';

export const fetchPaymentsHistory = (dates) => async (dispatch) => {
  const dateFrom = moment(dates[0]).format('YYYY-MM-DD');
  const dateTo = moment(dates[1]).format('YYYY-MM-DD');

  dispatch({
    type: FETCH_PAYMENTS_HISTORY_REQUEST,
    payload: {},
  });

  try {
    const response = await request(
      `${process.env.APP_USER_API_ROOT}/subscribe/payments-history/?date_from=${dateFrom}&date_to=${dateTo}`,
      {
        withCredentials: true,
      }
    );

    const {
      entities: { paymentsHistory: entities = {} } = {},
      result: ids = [],
    } =
      response.data && response.data.length
        ? normalize(response.data, schema)
        : {};

    return dispatch({
      type: FETCH_PAYMENTS_HISTORY_SUCCESS,
      payload: {
        ids,
        entities,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_PAYMENTS_HISTORY_FAILURE,
      payload: {},
    });
  }
};

export default fetchPaymentsHistory;
