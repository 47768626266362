import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

import IntlRoot from 'components/IntlRoot';

function Root({ store, routerBasename }) {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter basename={routerBasename}>
        <Route path="/:locale(ua|en)?/">
          <IntlRoot />
        </Route>
      </BrowserRouter>
    </ReduxProvider>
  );
}

Root.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  routerBasename: PropTypes.string.isRequired,
};

export default memo(Root);
