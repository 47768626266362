import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getActiveSubscription,
  getNewSubscription,
  getPayedInvoice,
} from 'selectors';
import { request } from 'utils';
import { fetchInvoices, fetchUserProfile } from 'actions';

import { useSelectorProps } from './useSelectorProps';

export const useCheckInvoice = () => {
  const dispatch = useDispatch();
  const selectorProps = useSelectorProps();

  const activeSubscription = useSelector((state) =>
    getActiveSubscription(state, selectorProps)
  );

  const newSubscription = useSelector((state) =>
    getNewSubscription(state, selectorProps)
  );

  const payedInvoice = useSelector((state) => getPayedInvoice(state));

  const activeSubscriptionIsFree = activeSubscription?.slug === 'free';

  useEffect(() => {
    if (activeSubscriptionIsFree && newSubscription) {
      let timerId = null;

      const requestPool = async function req() {
        try {
          const response = await request(
            `${process.env.APP_USER_API_ROOT}/subscribe/${payedInvoice.id}/`,
            { withCredentials: true }
          );

          if (response.data.status !== '0') {
            dispatch(fetchInvoices());
            dispatch(fetchUserProfile());
          } else {
            timerId = setTimeout(req, 10000);
          }
        } catch (err) {
          clearTimeout(timerId);
          timerId = setTimeout(req, 10000);
        }
      };

      timerId = setTimeout(requestPool, 10000);
    }
  }, [newSubscription?.id]);
};

export default useCheckInvoice;
